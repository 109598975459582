<template lang="pug">
  AppButton.btn-confirm(
    :class="{ short }"
    v-bind="$props"
    @click="$emit('save')"
  )
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: "save"
      },
      title: String,
      disabled: {
        type: Boolean,
        default: false
      },
      // add .short class for button
      short: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppButton: () => import("@/components/elements/AppButton")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .btn-confirm
    font-size: 0.8rem
    font-weight: normal
</style>
